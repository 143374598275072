import { createContext, ReactNode, useContext, useState } from "react";

// contexts
import { useAccessContext } from "../contexts/AccessContext";
import { useAddressContext } from "../contexts/AddressContext";
import { useInitialContext } from "../contexts/InitialContext";
import { useRegistrationContext } from "../contexts/RegistrationContext";
import { usePaymentContext } from "../contexts/PaymentContext";
import { useProductContext } from "../contexts/ProductContext";

// services
import { ServiceAPI } from "../services/api";

//models
import { CheckoutModel } from "../models/CheckoutModel";

interface CheckoutFormContextModel {
  checkout: () => Promise<any>;
  verifyRecaptcha: (recaptchakey: string) => Promise<any>;
  isPaid: boolean;
  validatedRecaptcha: boolean;
  setValidatedRecaptcha: (recaptchaStatus: boolean) => void;
}

interface CheckoutFormContextProps {
  children?: ReactNode;
}

const CheckoutFormContext = createContext({} as CheckoutFormContextModel);

export function CheckoutFormContextProvider({
  children,
}: CheckoutFormContextProps) {
  const { initialData } = useInitialContext();
  const { accessData } = useAccessContext();
  const { addressData } = useAddressContext();
  const { registrationData } = useRegistrationContext();
  const { paymentData } = usePaymentContext();
  const { productData, setIsLoading } = useProductContext();

  const [validatedRecaptcha, setValidatedRecaptcha] = useState(false);
  const [isPaid, setIsPaid] = useState(false);

  let checkoutData: CheckoutModel = {
    clientIdentificationDoc: initialData.cpfOrCnpj,
    groupId: initialData.seller.id_grupo_grp,

    clientEmail: accessData.email,
    password: accessData.password,
    passwordConfirmation: accessData.confirmPassword,

    clientName: registrationData.companyName,
    freeIcms: registrationData.freeIcms,
    nacionalSimpleOptant: registrationData.nacionalSimpleOptant,
    stateRegistration: registrationData.stateRegistration,
    municipalRegistration: registrationData.municipalRegistration,
    clientPhone: registrationData.telephone,

    cep: addressData.cep,
    address: addressData.address,
    addressNumber: addressData.number,
    complement: addressData.complement,
    district: addressData.district,
    city: addressData.city,
    state: addressData.state,

    paymentMethod: paymentData.bankSlip ? "0" : "3",
    creditCardName: paymentData.creditCardName,
    creditCardNumber: String(paymentData.creditCardNumber).replace(/[^0-9]+/g, ""),
    creditCardValidityMonth: String(paymentData.creditCardValidity).split("/")[0],
    creditCardValidityYear: String(paymentData.creditCardValidity).split("/")[1],
    creditCardCvv: String(paymentData.creditCardCvv).replace(
      /[^0-9]+/g,
      ""
    ),
    creditCardFlag: paymentData.creditCardFlag,

    coupon: productData.coupon,

    contractAcceptance: paymentData.policyPrivacy ? "1" : "0",
  };

  const checkout = (): Promise<any> =>
    new Promise<any>(async (resolve, reject) => {
      try {
        if (initialData.seller.id_grupo_grp !== "") {
          if (paymentData.policyPrivacy) {
            setIsLoading(true);
            ServiceAPI.post("/checkout?product=Conversor", checkoutData, {
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                setIsLoading(false);
                setIsPaid(true);
                resolve(response);
              })
              .catch((err) => {
                setIsLoading(false);

                if (err.response) {
                  reject(err.response.data.msg);
                }

                reject("Ocorreu um erro inesperado");
              });
          } else {
            reject("Você não aceitou a política de privacidade.");
          }
        } else {
          reject("Algo deu errado na seleção de vendedor.");
        }
      } catch (err) {
        reject("Não conseguimos realizar esta compra.");
      }
    });

  const verifyRecaptcha = (recaptchakey: string): Promise<any> =>
    new Promise<any>(async (resolve, reject) => {
      try {
        ServiceAPI.post(
          `/check-robot`,
          { recaptchakey: recaptchakey },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then(() => {
            resolve("Identidade validada.");
          })
          .catch((err) => {
            err.response
              ? reject(err.response.data.msg)
              : reject(
                  "Ocorreu um erro inesperado ao verificar sua identidade"
                );
          });
      } catch (err) {
        err.response
          ? reject(err.response.data.msg)
          : reject("Ocorreu um erro inesperado ao verificar sua identidade");
      }
    });

  return (
    <CheckoutFormContext.Provider
      value={{
        checkout,
        validatedRecaptcha,
        setValidatedRecaptcha,
        verifyRecaptcha,
        isPaid,
      }}
    >
      {children}
    </CheckoutFormContext.Provider>
  );
}

export function useCheckoutFormContext() {
  const checkoutFormContext = useContext(CheckoutFormContext);
  const {
    checkout,
    validatedRecaptcha,
    setValidatedRecaptcha,
    verifyRecaptcha,
    isPaid,
  } = checkoutFormContext;

  return {
    checkout,
    validatedRecaptcha,
    setValidatedRecaptcha,
    verifyRecaptcha,
    isPaid,
  };
}
