import axios from "axios";

// Checkout API
export const ServiceAPI = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_API,
});

// ViaCep API
export const ViaCepAPI = axios.create({
  baseURL: process.env.REACT_APP_VIA_CEP_API,
});

// API Verify Recaptcha
export const RecaptchaAPI = axios.create({
  baseURL: process.env.RECAPTCHA_API,
});
