import React from "react";
import { Header } from "semantic-ui-react";

import styles from "../styles/components/MyHeader.module.scss";

interface MyHeaderProps {
  children: any;
}

const MyHeader = ({ children }: MyHeaderProps) => {
  return (
    <Header as="h1" className={styles.heading}>
      {children}
    </Header>
  );
};

export default MyHeader;
