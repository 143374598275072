import { createContext, ReactNode, useState, useContext } from "react";

import { InitialModel, SellerModel } from "../models/InitialModel";
import { ServiceAPI } from "../services/api";

import { toast } from "react-toastify";

interface InitialContextModel {
  setInitialData: (initialData: InitialModel) => void;
  changeSituation: (situation: string) => void;
  changeInitialData: (sellerName: string) => void;
  initialData: InitialModel;
}

interface InitialContextProps {
  children?: ReactNode;
}

const InitialContext = createContext({} as InitialContextModel);

export function InitialContextProvider({ children }: InitialContextProps) {
  const [initialData, setInitialData] = useState<InitialModel>({
    personType: "",
    seller: {
      st_nome_grp: "",
      id_grupo_grp: "",
    },
    cpfOrCnpj: "",
    situation: "to-do",
  });

  function changeSituation(situation: string) {
    setInitialData({
      ...initialData,
      situation: situation,
    });
  }
  async function changeInitialData(formData: any) {
    try {
      const apiData: Array<SellerModel> = await ServiceAPI.get(
        "/sellers?product=Conversor"
      ).then((res: any) => {
        return res.data;
      });

      let sellerId = "";

      apiData.forEach((oneSeller) => {
        if (oneSeller.st_nome_grp === String(formData.seller)) {
          sellerId = String(oneSeller.id_grupo_grp);
        }
      });

      return setInitialData({
        personType: formData.personType,
        seller: {
          st_nome_grp: formData.seller,
          id_grupo_grp: sellerId,
        },
        cpfOrCnpj: formData.cpfOrCnpj,
        situation: "complete",
      });
    } catch (err) {
      console.error(err);
      toast.error("Não conseguimos salvar seus dados.");
    }
  }

  return (
    <InitialContext.Provider
      value={{
        initialData,
        setInitialData,
        changeSituation,
        changeInitialData,
      }}
    >
      {children}
    </InitialContext.Provider>
  );
}

export function useInitialContext() {
  const initialContext = useContext(InitialContext);
  const { initialData, setInitialData, changeSituation, changeInitialData } =
    initialContext;

  return { initialData, setInitialData, changeSituation, changeInitialData };
}
