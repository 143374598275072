import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// contexts / providers / services
import { useCheckoutFormContext } from "../contexts/CheckoutFormContext";
import { useProductContext } from "../contexts/ProductContext";
import { usePaymentContext } from "../contexts/PaymentContext";

// form
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  returnCreditCardFlag,
  isValidCreditCardNumber,
  cardValidityValidator,
  cardNumberMask,
  cardValidityMask,
  cardCvvMask,
} from "../utils/formValidators";
import InputMask from "react-text-mask";

// stylized components
import MyHeader from "./MyHeader";
import MyInput from "./MyInput";
import MyButton from "./MyButton";
import MyInputError from "./MyInputError";

// rgzifNCsw
import SimplePurchaseSummary from "./SimplePurchaseSummary";

// styles, etc
import {
  Container,
  Grid,
  Button,
  Checkbox,
  Modal,
  Loader,
} from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faArrowLeft,
  faFileInvoiceDollar,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import styles from "../styles/components/PaymentData.module.scss";

function PaymentData() {
  const [open, setOpen] = useState(false);
  const [creditCardFlag, setCreditCardFlag] = useState("");
  const { paymentData, setPaymentData, changeSituation } = usePaymentContext();
  const { checkout } = useCheckoutFormContext();
  const { changeStep, isLoading } = useProductContext();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    bankSlip: Yup.boolean().required("*"),
    creditCardNumber: Yup.string().when("bankSlip", {
      is: false,
      then: Yup.string()
        .test("is-valid-credit-card-flag", "*", (value) =>
          isValidCreditCardNumber(String(value))
        )
        .required("*"),
    }),
    creditCardName: Yup.string().when("bankSlip", {
      is: false,
      then: Yup.string().required("*"),
    }),
    creditCardCvv: Yup.string().when("bankSlip", {
      is: false,
      then: Yup.string().required("*"),
    }),
    creditCardValidity: Yup.string().when("bankSlip", {
      is: false,
      then: Yup.string()
        .test("card-validity", "*", (value) =>
          cardValidityValidator(String(value))
        )
        .required("*"),
    }),
    policyPrivacy: Yup.bool().oneOf([true], "*"),
  });
  //
  const formik = useFormik({
    initialValues: {
      creditCardNumber: paymentData.creditCardNumber,
      creditCardName: paymentData.creditCardName,
      creditCardCvv: paymentData.creditCardCvv,
      creditCardValidity: paymentData.creditCardValidity,
      bankSlip: paymentData.bankSlip,
      policyPrivacy: false,
      recaptchaKey: "",
      validatedRecaptcha: false,
    },
    onSubmit: (formData) => {
      submitPaymentData(formData);
    },
    validationSchema,
  });

  function submitPaymentData(formData: any) {
    setPaymentData({
      ...formData,
      creditCardFlag: creditCardFlag,
      situation: "complete",
    });
  }

  function bankSlipSelect() {
    setPaymentData({ ...paymentData, bankSlip: true });
    formik.setFieldValue("bankSlip", true);
  }

  function creditCardSelect() {
    setPaymentData({ ...paymentData, bankSlip: false });
    formik.setFieldValue("bankSlip", false);
  }

  useEffect(() => {
    setCreditCardFlag(returnCreditCardFlag(formik.values.creditCardNumber));
  }, [formik.values.creditCardNumber]);

  useEffect(() => {
    formik.setFieldTouched("creditCardNumber");
    changeSituation("doing");
    // eslint-disable-next-line
  }, []);

  return (
    <Container className={styles.paymentContainer}>
      <MyHeader>Forma de pagamento</MyHeader>

      <Container>
        <Button.Group attached="top" className={styles.paymentForm}>
          <Button
            className={
              paymentData.bankSlip ? styles.myDisabled : styles.myActive
            }
            onClick={creditCardSelect}
          >
            Cartão
          </Button>
          <Button
            className={
              paymentData.bankSlip ? styles.myActive : styles.myDisabled
            }
            onClick={bankSlipSelect}
          >
            Boleto
          </Button>
        </Button.Group>

        {paymentData.bankSlip ? (
          <Container className={styles.containerPaymentMethod}>
            <Container className={styles.bankSlipContainer}>
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
              <p>
                Acesse o boleto na próxima página ou pelo seu e-mail na caixa de
                entrada ou spam.
              </p>
            </Container>
          </Container>
        ) : (
          <Container className={styles.containerPaymentMethod}>
            <Container className={styles.creditCardContainer}>
              <Grid className={styles.creditCardGrid}>
                <Grid.Row className={styles.creditCardRow}>
                  <Grid.Column computer={11} mobile={16} tablet={16}>
                    <MyInput
                      name="creditCardNumber"
                      type="text"
                      label={
                        <>
                          <span>Número do cartão:</span>
                          <MyInputError
                            error={formik.errors.creditCardNumber}
                          />
                        </>
                      }
                      value={formik.values.creditCardNumber}
                    >
                      <InputMask
                        id="creditCardNumber"
                        name="creditCardNumber"
                        type="text"
                        mask={cardNumberMask}
                        placeholder="9999 9999 9999 9999"
                        value={formik.values.creditCardNumber}
                        onChange={formik.handleChange}
                      />
                    </MyInput>
                  </Grid.Column>
                  <Grid.Column computer={5} mobile={16} tablet={16}>
                    <MyInput
                      name="creditCardValidity"
                      type="text"
                      label={
                        <>
                          <span>Validade:</span>
                          <MyInputError
                            error={formik.errors.creditCardValidity}
                          />
                        </>
                      }
                      value={formik.values.creditCardValidity}
                    >
                      <InputMask
                        id="creditCardValidity"
                        name="creditCardValidity"
                        type="text"
                        mask={cardValidityMask}
                        placeholder="12/2029"
                        value={formik.values.creditCardValidity}
                        onChange={formik.handleChange}
                      />
                    </MyInput>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column computer={12} mobile={16} tablet={16}>
                    <MyInput
                      id="creditCardName"
                      name="creditCardName"
                      type="text"
                      placeholder="João da Silva"
                      value={formik.values.creditCardName}
                      onChange={formik.handleChange}
                      label={
                        <>
                          <span>Nome do cartão:</span>
                          <MyInputError error={formik.errors.creditCardName} />
                        </>
                      }
                    />
                  </Grid.Column>
                  <Grid.Column computer={4} mobile={16} tablet={16}>
                    <MyInput
                      name="creditCardCvv"
                      type="text"
                      label={
                        <>
                          <span>CVV:</span>
                          <MyInputError error={formik.errors.creditCardCvv} />
                        </>
                      }
                      value={formik.values.creditCardCvv}
                    >
                      <InputMask
                        id="creditCardCvv"
                        name="creditCardCvv"
                        type="text"
                        mask={cardCvvMask}
                        placeholder="999"
                        value={formik.values.creditCardCvv}
                        onChange={formik.handleChange}
                      />
                    </MyInput>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Container>
        )}
      </Container>

      <Container className={styles.policyPrivacy}>
        <Checkbox
          id="policyPrivacy"
          name="policyPrivacy"
          checked={formik.values.policyPrivacy}
          onChange={(e, { checked }) =>
            formik.setFieldValue("policyPrivacy", checked)
          }
          label={{
            children: (
              <span>
                Li e aceito a{" "}
                <a
                  href="https://www.jdrel.com.br/politica-de-privacidade/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  política de privacidade
                </a>
                {" "} e os {" "}
                <a
                  href="https://jdrelconversor.com.br/termos-de-servico/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  termos de serviço
                </a>

                <MyInputError error={formik.errors.policyPrivacy} />
              </span>
            ),
          }}
        />
      </Container>

      <Container className="containerCommandButtons">
        <MyButton
          icon
          onClick={() => {
            formik.submitForm();
            changeStep("coupon-data");
            changeSituation("to-do");
          }}
          className="backButton"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          VOLTAR
        </MyButton>

        <Modal
          basic
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={
            <MyButton
              disabled={!formik.isValid}
              icon
              onClick={() => {
                formik.submitForm();
                setOpen(true);
              }}
            >
              COMPRAR
              <FontAwesomeIcon icon={faCheckCircle} />
            </MyButton>
          }
        >
          <Modal.Content>
            {isLoading ? (
              <Loader indeterminate>Finalizando compra...</Loader>
            ) : (
              <>
                <div className={styles.containerPurchaseSummary}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="2x"
                    onClick={() => setOpen(false)}
                  />
                  <h1>Resumo da compra</h1>

                  <SimplePurchaseSummary />
                  <MyButton
                    disabled={!formik.isValid}
                    icon
                    onClick={() =>
                      checkout()
                        .then((response) => {
                          history.push("/sucesso", response.data.link_boleto);
                        })
                        .catch((err) => {
                          toast.error(err);
                        })
                    }
                  >
                    FINALIZAR COMPRA
                  </MyButton>
                </div>
              </>
            )}
          </Modal.Content>
        </Modal>
      </Container>
    </Container>
  );
}

export default PaymentData;
