import React from "react";

import { Grid } from "semantic-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import { useAccessContext } from "../contexts/AccessContext";
import { useAddressContext } from "../contexts/AddressContext";
import { useInitialContext } from "../contexts/InitialContext";
import { useRegistrationContext } from "../contexts/RegistrationContext";
import { usePaymentContext } from "../contexts/PaymentContext";
import { useProductContext } from "../contexts/ProductContext";

import styles from "../styles/components/Steps.module.scss";

function Steps() {
  const { initialData } = useInitialContext();
  const { accessData } = useAccessContext();
  const { addressData } = useAddressContext();
  const { registrationData } = useRegistrationContext();
  const { paymentData } = usePaymentContext();
  const { productData } = useProductContext();

  function defineSituation(value: string) {
    switch (value) {
      case "to-do":
        return <FontAwesomeIcon icon={faCircle} size="1x" />;
      case "doing":
        return <FontAwesomeIcon icon={faUserEdit} size="2x" />;
      case "complete":
        return <FontAwesomeIcon icon={faUserCheck} size="2x" />;
      default:
        return <FontAwesomeIcon icon={faCircle} size="1x" />;
    }
  }

  function setClass(situation: string) {
    switch (situation) {
      case "complete":
        return styles.completeStepItem;
      case "doing":
        return styles.stepItem;
      case "to-do":
        return styles.toDoStepItem;
      default:
        return styles.stepItem;
    }
  }

  return (
    <>
      <Grid centered className={styles.stepsContainer}>
        <Grid.Row centered only="computer">
          <Grid.Column width={2} className={setClass(initialData.situation)}>
            {defineSituation(initialData.situation)}
            <p>Dados iniciais</p>
          </Grid.Column>
          <Grid.Column
            width={2}
            className={setClass(registrationData.situation)}
          >
            {defineSituation(registrationData.situation)}
            <p>Dados cadastrais</p>
          </Grid.Column>
          <Grid.Column width={2} className={setClass(addressData.situation)}>
            {defineSituation(addressData.situation)}
            <p>Endereço</p>
          </Grid.Column>
          <Grid.Column width={2} className={setClass(accessData.situation)}>
            {defineSituation(accessData.situation)}
            <p>Dados de acesso</p>
          </Grid.Column>
          <Grid.Column
            width={2}
            className={setClass(productData.couponSituation)}
          >
            {defineSituation(productData.couponSituation)}
            <p>Cupom</p>
          </Grid.Column>
          <Grid.Column width={2} className={setClass(paymentData.situation)}>
            {defineSituation(paymentData.situation)}
            <p>Pagamento</p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={6} centered only="mobile tablet">
          <Grid.Column className={setClass(initialData.situation)}>
            {defineSituation(initialData.situation)}
          </Grid.Column>
          <Grid.Column className={setClass(registrationData.situation)}>
            {defineSituation(registrationData.situation)}
          </Grid.Column>
          <Grid.Column className={setClass(addressData.situation)}>
            {defineSituation(addressData.situation)}
          </Grid.Column>
          <Grid.Column className={setClass(accessData.situation)}>
            {defineSituation(accessData.situation)}
          </Grid.Column>
          <Grid.Column className={setClass(productData.couponSituation)}>
            {defineSituation(productData.couponSituation)}
          </Grid.Column>
          <Grid.Column className={setClass(paymentData.situation)}>
            {defineSituation(paymentData.situation)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default Steps;
