import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Form from "./pages/Form";
import Success from "./pages/Success";
import NotFound from "./pages/NotFound";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Form} />
        <Route exact path="/sucesso" component={Success} />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}
