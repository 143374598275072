import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from "react";

import { ProductModel, CouponModel } from "../models/ProductModel";
import { ServiceAPI } from "../services/api";
import { toast } from "react-toastify";

interface ProductContextModel {
  productData: ProductModel;
  applyCoupon: (coupon: string) => Promise<any>;
  activeCoupon: boolean;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  changeCouponSituation: (situation: string) => void;
  changeStep: (step: string) => void;
  formStep: string;
}

interface ProductContextProps {
  children?: ReactNode;
}

const ProductContext = createContext({} as ProductContextModel);

export function ProductContextProvider({ children }: ProductContextProps) {
  const [formStep, setFormStep] = useState("initial-data");
  const [productData, setProductData] = useState<ProductModel>({
    adesao: "",
    destaque: "",
    descricao: "",
    nome: "",
    nomeGrade: "",
    id: "",
    mensalidade: "",
    valorPrimeiraCobranca: "",
    desativado: "",
    coupon: "",
    couponSituation: "to-do",
    desconto: 0,
  });
  const [activeCoupon, setActiveCoupon] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  function changeStep(step: string) {
    setFormStep(step);
  }

  function changeCouponSituation(situation: string) {
    setProductData({
      ...productData,
      couponSituation: situation,
    });
  }

  async function applyCoupon(coupon: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const apiCouponResponse = await ServiceAPI.get(
          `/coupon/${coupon}?product=Conversor`
        );

        const couponResponse: CouponModel = apiCouponResponse.data[0];

        if (couponResponse.data) {
          if (
            Number(couponResponse.data.fl_percentualadesao_cup) === Number("1")
          ) {
            const ruleOf3 =
              Number(productData.adesao) *
              Number(couponResponse.data.vl_descontoadesao_cup);

            setProductData({
              ...productData,
              desconto: Number(ruleOf3) / 100,
              coupon: coupon,
            });

            resolve(setActiveCoupon(true));
          }

          if (
            Number(couponResponse.data.fl_percentualadesao_cup) === Number("0")
          ) {
            setProductData({
              ...productData,
              desconto: Number(couponResponse.data.vl_descontoadesao_cup),
              coupon: coupon,
            });

            resolve(setActiveCoupon(true));
          }
        } else {
          reject("Cupom não localizado.");
        }
      } catch {
        reject("Não conseguimos validar seu cupom.");
      }
    });
  }

  useEffect(() => {
    async function getProduct() {
      try {
        const product = await ServiceAPI.get(
          "/plan?product=Conversor"
        ).then((response) => {
          return response.data[0];
        });
        setProductData({
          ...productData,
          adesao: product.adesao,
          destaque: product.destaque,
          descricao: product.descricao,
          nome: product.nome,
          nomeGrade: product.nomeGrade,
          id: product.id,
          mensalidade: product.mensalidade,
          valorPrimeiraCobranca: product.valorPrimeiraCobranca,
          desativado: product.desativado,
        });
      } catch {
        toast.error("Houve um problema ao obter dados do JDREL Conversor.");
      }
    }

    getProduct();
    // eslint-disable-next-line
  }, []);

  return (
    <ProductContext.Provider
      value={{
        productData,
        applyCoupon,
        activeCoupon,
        isLoading,
        setIsLoading,
        changeStep,
        formStep,
        changeCouponSituation,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

export function useProductContext() {
  const productContext = useContext(ProductContext);
  const {
    productData,
    applyCoupon,
    activeCoupon,
    isLoading,
    setIsLoading,
    changeStep,
    formStep,
    changeCouponSituation,
  } = productContext;

  return {
    productData,
    applyCoupon,
    activeCoupon,
    isLoading,
    setIsLoading,
    changeStep,
    formStep,
    changeCouponSituation,
  };
}
