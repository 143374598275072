import React, { useEffect, useState } from "react";

// contexts / providers / services
import { useProductContext } from "../contexts/ProductContext";
import { useAccessContext } from "../contexts/AccessContext";

// form
import { useFormik } from "formik";
import * as Yup from "yup";
import { emailValidator } from "../utils/formValidators";
import { returnFormMessage } from "../utils/formMessages";

// stylized components
import MyHeader from "./MyHeader";
import MyInput from "./MyInput";
import MyButton from "./MyButton";
import MyInputError from "./MyInputError";

// styles, etc
import { Container, Grid, Message } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../styles/components/AccessData.module.scss";
import { toast } from "react-toastify";

function AccessData() {
  const { accessData, setAccessData, changeSituation } = useAccessContext();
  const { changeStep } = useProductContext();

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [confirmPasswordIsVisible, setConfirmPasswordIsVisible] =
    useState(false);

  const pfRegistrationFields = [
    { fieldName: "email", coolName: "e-mail" },
    { fieldName: "password", coolName: "senha" },
    { fieldName: "confirmPassword", coolName: "confirmação de senha" },
  ];

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("*")
      .required("*")
      .test("is-email", "*", (value) => emailValidator(String(value))),
    password: Yup.string().min(8, "*").required("*"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "*")
      .required("*"),
  });

  const formik = useFormik({
    initialValues: {
      email: accessData.email,
      password: accessData.password,
      confirmPassword: accessData.confirmPassword,
    },
    onSubmit: (formData) => {
      submitAccessData(formData);
    },
    validationSchema,
  });

  function submitAccessData(formData: any) {
    setAccessData({
      ...formData,
      situation: "complete",
    });

    changeStep("coupon-data");
  }

  useEffect(() => {
    changeSituation("doing");
    formik.setFieldTouched("email");
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="containerFormStep">
      <MyHeader>Dados de acesso</MyHeader>

      <Container className="inputsContainer">
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <MyInput
                id="email"
                name="email"
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Insira seu e-mail para cadastro:</span>
                    <MyInputError error={formik.errors.email} />
                  </>
                }
                placeholder="joao.silva@gmail.com"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column
              computer={8}
              mobile={16}
              tablet={16}
              className={styles.passwordGrid}
            >
              <MyInput
                id="password"
                name="password"
                type={passwordIsVisible ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Senha:</span>
                    <MyInputError error={formik.errors.password} />
                  </>
                }
                placeholder="*********"
                icon={
                  <FontAwesomeIcon
                    icon={passwordIsVisible ? faEye : faEyeSlash}
                    onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                    className={styles.inputIcon}
                  />
                }
              />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
              <MyInput
                id="confirmPassword"
                name="confirmPassword"
                type={confirmPasswordIsVisible ? "text" : "password"}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                placeholder="*********"
                label={
                  <>
                    <span>Confirme sua senha:</span>
                    <MyInputError error={formik.errors.confirmPassword} />
                  </>
                }
                icon={
                  <FontAwesomeIcon
                    icon={confirmPasswordIsVisible ? faEye : faEyeSlash}
                    onClick={() =>
                      setConfirmPasswordIsVisible(!confirmPasswordIsVisible)
                    }
                    className={styles.inputIcon}
                  />
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {formik.errors.password && (
          <Message negative size="tiny">
            Sua senha deve ter pelo menos 8 caracteres.
          </Message>
        )}
      </Container>

      <Container className="containerCommandButtons">
        <MyButton
          icon
          onClick={() => {
            changeStep("address-data");
            changeSituation("to-do");
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          VOLTAR
        </MyButton>
        <MyButton
          icon
          onClick={() =>
            formik.isValid
              ? formik.submitForm()
              : returnFormMessage(
                  formik.isValid,
                  formik.errors,
                  formik.values,
                  pfRegistrationFields
                )
                  .then((message) => toast.error(message))
                  .catch(() => {
                    toast.error(
                      "Algum campo não possui o valor que esperamos."
                    );
                  })
          }
        >
          AVANÇAR
          <FontAwesomeIcon icon={faArrowRight} />
        </MyButton>
      </Container>
    </Container>
  );
}

export default AccessData;
