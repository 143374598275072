import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

import "../styles/components/MyInputError.module.scss";

interface MyInputError
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  error: string;
}

const InputError = ({ error, ...rest }: MyInputError) => (
  <small className="errors" {...rest}>
    {error}
  </small>
);

export default observer(InputError);

InputError.propTypes = {
  error: PropTypes.string,
};

InputError.defaultProps = {
  error: PropTypes.string,
};
