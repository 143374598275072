import React, { createContext, ReactNode, useState, useContext } from "react";

import { useAddressContext } from "../contexts/AddressContext";
import { useProductContext } from "../contexts/ProductContext";
import { RegistrationModel, CompanyModel } from "../models/RegistrationModel";
import { ServiceAPI } from "../services/api";
import { toast } from "react-toastify";

interface RegistrationContextModel {
  registrationData: RegistrationModel;
  setRegistrationData: (registrationData: RegistrationModel) => void;
  changeSituation: (situation: string) => void;
  setCompanyData: (cnpj: string) => void;
}

interface RegistrationContextProps {
  children?: ReactNode;
}

const RegistrationContext = createContext({} as RegistrationContextModel);

export function RegistrationContextProvider({
  children,
}: RegistrationContextProps) {
  const [registrationData, setRegistrationData] = useState<RegistrationModel>({
    companyName: "",
    telephone: "",
    freeIcms: "",
    nacionalSimpleOptant: "",
    municipalRegistration: "",
    hasMunicipalRegistration: "",
    stateRegistration: "",
    situation: "to-do",
  });
  const { addressData, setAddressData } = useAddressContext();
  const { setIsLoading, changeStep } = useProductContext();

  async function setCompanyData(cnpj: string) {
    setIsLoading(true);
    try {
      const companyData: CompanyModel = await ServiceAPI.get(
        `/company/${cnpj}`
      );

      if (companyData.status === 204) {
        toast.warning("Esta empresa não consta em nossos dados.");
      } else {
        setRegistrationData({
          ...registrationData,
          companyName: String(companyData.data.razaoSocial),
          nacionalSimpleOptant: String(companyData.data.opcaoPeloSimples),
        });

        setAddressData({
          ...addressData,
          cep: String(companyData.data.cep),
          number: String(companyData.data.numero),
          complement: String(companyData.data.complemento),
        });
      }

      changeStep("pj-registration-data");
      setIsLoading(false);
    } catch (err) {
      changeStep("pj-registration-data");
      setIsLoading(false);
      console.error(err);
      toast.error("Não conseguimos carregar os dados da sua empresa.");
    }
  }

  function changeSituation(situation: string) {
    setRegistrationData({
      ...registrationData,
      situation: situation,
    });
  }

  return (
    <RegistrationContext.Provider
      value={{
        registrationData,
        setRegistrationData,
        changeSituation,
        setCompanyData,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
}

export function useRegistrationContext() {
  const registrationContext = useContext(RegistrationContext);
  const {
    registrationData,
    setRegistrationData,
    changeSituation,
    setCompanyData,
  } = registrationContext;

  return {
    registrationData,
    setRegistrationData,
    changeSituation,
    setCompanyData,
  };
}
