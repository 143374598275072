const cards = {
  visa: /^4[0-9]{12}(?:[0-9]{3})/, // bandeira aceita
  mastercard: /^5[1-5][0-9]{14}/, // bandeira aceita
  diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/, // bandeira aceita
  amex: /^3[47][0-9]{13}/, // bandeira aceita
  discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
  hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
  elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/, // bandeira aceita
  jcb: /^(?:2131|1800|35\d{3})\d{11}/,
  aura: /^(5078\d{2})(\d{2})(\d{11})$/,
};

export function returnCreditCardFlag(cardNumber: string) {
  let cardFlag: string = "error";
  if (cardNumber) {
    cardNumber = String(cardNumber).replace(/[^0-9]+/g, "");
    Object.entries(cards).forEach(([flag, regExp]) => {
      if (regExp.test(cardNumber)) {
        switch (flag) {
          case "visa":
            cardFlag = "visa";
            break;
          case "mastercard":
            cardFlag = "mastercard";
            break;
          case "diners":
            cardFlag = "diners";
            break;
          case "amex":
            cardFlag = "amex";
            break;
          case "elo":
            cardFlag = "elo";
            break;
          default:
            cardFlag = "A bandeira deste cartão não é aceita.";
            break;
        }
        if (cardFlag !== "error" || "A bandeira deste cartão não é aceita.") {
          return cardFlag;
        } else {
          return cardFlag;
        }
      } else {
        if (cardFlag !== "error" || "A bandeira deste cartão não é aceita.") {
          return cardFlag;
        } else {
          cardFlag = "Número do cartão inválido.";
          return cardFlag;
        }
      }
    });
    return cardFlag;
  } else {
    return "";
  }
}

export function isValidCreditCardNumber(cardNumber: string) {
  if (cardNumber) {
    cardNumber = cardNumber.replace(/[^0-9]+/g, "");
    let thisFlag: boolean = false;

    Object.entries(cards).forEach(([flag, regExp]) => {
      if (regExp.test(cardNumber)) {
        switch (flag) {
          case "visa":
          case "mastercard":
          case "diners":
          case "amex":
          case "elo":
            thisFlag = true;
            break;
          default:
            thisFlag = false;
            break;
        }
      } else {
        return false;
      }
    });
    return thisFlag;
  } else {
    return false;
  }
}

export function creditCardFlag(flag: string) {
  if (flag !== "visa" || "mastercard" || "diners" || "amex" || "elo") {
    return false;
  }
}

export function emailValidator(email: string) {
  let emailIsValid: boolean = false;
  // eslint-disable-next-line
  const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email) {
    emailIsValid = emailRegExp.test(String(email).toLowerCase());
  }

  return emailIsValid;
}

export function cardValidityValidator(cardValidity: string) {
  let cardValidityIsValid: boolean = false;

  const thisYear = new Date().getFullYear();
  const trueYearValidity = thisYear + 16;

  const thisMonth = new Date().getMonth() + 1;

  if (cardValidity) {
    const month = cardValidity.split("/")[0];
    const year = cardValidity.split("/")[1];

    cardValidityIsValid = Number(month) <= 12;
    cardValidityIsValid =
      Number(year) >= thisYear && Number(year) < trueYearValidity;

    if (Number(year) === Number(thisYear)) {
      cardValidityIsValid = Number(month) >= Number(thisMonth);
    }
  }

  return cardValidityIsValid;
}

export function phoneValidator(phone: string) {
  const phoneRegExp = new RegExp(
    "^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$"
  );

  phone = phone.replace(/[^0-9]+/g, "");
  return phoneRegExp.test(phone);
}

// input masks for https://www.npmjs.com/package/react-text-mask
export const cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

export const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

export const cnpjMask = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

export const phoneMask = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const cardNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const cardValidityMask = [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
export const cardCvvMask = [/\d/, /\d/, /\d/];
