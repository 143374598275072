import React from "react";

// contexts / providers / services / utils
import { useProductContext } from "../contexts/ProductContext";
import { moneyFormat } from "../utils/moneyFormat";

// styles, stylized components, etc
import styles from "../styles/components/PurchaseSummary.module.scss";
import {
  Container,
  Header,
  Divider,
  Grid,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import MyHeader from "./MyHeader";

function PurchaseSummary() {
  const { productData } = useProductContext();

  return (
    <Container className={styles.container}>
      <MyHeader>Resumo da Compra</MyHeader>

      <Container className={styles.productArea}>
        {productData.nome ? (
          <>
            <Header className={styles.product} textAlign="center">
              <h4>{productData.nome}</h4>

              <Header.Subheader className={styles.productDescription}>
                <p>
                  Plano mensal com 15 leiautes exclusivo para clientes Domínio.
                  <br />+ Possibilidade de adquirir novos leiautes
                  <strong> após </strong>sua contratação.
                </p>
              </Header.Subheader>
            </Header>
            <Grid textAlign="center" className={styles.listProduct}>
              <Grid.Column width={8} className={styles.listDescriptions}>
                <p>Adesão</p>
                <p>Mensalidade</p>
                {productData.desconto !== 0 && (
                  <>
                    <p className={styles.discount}>Desconto</p>
                  </>
                )}
              </Grid.Column>

              <Grid.Column width={8} className={styles.listValues}>
                <p>{moneyFormat(Number(productData.adesao))}</p>
                <p>{moneyFormat(Number(productData.mensalidade))}</p>
                {productData.desconto !== 0 && (
                  <>
                    <p className={styles.discount}>
                      - {moneyFormat(Number(productData.desconto))}
                    </p>
                  </>
                )}
              </Grid.Column>
            </Grid>
            <Divider section className={styles.divider} />
            <Grid textAlign="center" className={styles.listTotal}>
              <Grid.Column width={5} className={styles.totalDescription}>
                <h4>Total</h4>
              </Grid.Column>

              <Grid.Column width={11} className={styles.totalValue}>
                <h4>
                  {moneyFormat(
                    Number(productData.adesao) +
                      Number(productData.mensalidade) -
                      Number(productData.desconto)
                  )}
                </h4>
              </Grid.Column>
            </Grid>
          </>
        ) : (
          <>
            <Segment className={styles.loader}>
              <Dimmer active>
                <Loader indeterminate>Carregando dados do plano</Loader>
              </Dimmer>

              <Header className={styles.product} textAlign="center">
                <h4>JDREL Conversor</h4>

                <Header.Subheader className={styles.productDescription}>
                  <p>
                    Plano mensal com 15 leiautes exclusivo para clientes
                    Domínio.
                    <br />+ Possibilidade de adquirir novos leiautes
                    <strong> após </strong>sua contratação.
                  </p>
                </Header.Subheader>
              </Header>
              <Grid textAlign="center" className={styles.listProduct}>
                <Grid.Column width={8} className={styles.listDescriptions}>
                  <p>Adesão</p>
                  <p>Mensalidade</p>
                  {productData.desconto !== 0 && (
                    <>
                      <p className={styles.discount}>Desconto</p>
                    </>
                  )}
                </Grid.Column>

                <Grid.Column width={8} className={styles.listValues}>
                  <p>R$ 0,00</p>
                  <p>R$ 0,00</p>
                </Grid.Column>
              </Grid>
              <Divider section className={styles.divider} />
              <Grid textAlign="center" className={styles.listTotal}>
                <Grid.Column width={5} className={styles.totalDescription}>
                  <h4>Total</h4>
                </Grid.Column>

                <Grid.Column width={11} className={styles.totalValue}>
                  <h4>R$ 0,00</h4>
                </Grid.Column>
              </Grid>
            </Segment>
          </>
        )}
      </Container>
    </Container>
  );
}

export default PurchaseSummary;
