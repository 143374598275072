import React, { useEffect } from "react";

// contexts / providers / services
import { useProductContext } from "../contexts/ProductContext";
import { useRegistrationContext } from "../contexts/RegistrationContext";

// form
import { useFormik } from "formik";
import * as Yup from "yup";
import { returnFormMessage } from "../utils/formMessages";
import { phoneValidator, phoneMask } from "../utils/formValidators";
import InputMask from "react-text-mask";

// stylized components
import MyHeader from "./MyHeader";
import MyInput from "./MyInput";
import MyButton from "./MyButton";
import MyInputError from "./MyInputError";
import MySelect from "./MySelect";

// styles, etc
import { Container, Grid } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function PjRegistrationData() {
  const { registrationData, setRegistrationData, changeSituation } =
    useRegistrationContext();
  const { changeStep } = useProductContext();

  const freeIcmsOptions = [
    { key: "Sim", value: "1", text: "Sim" },
    { key: "Não", value: "0", text: "Não" },
  ];
  const nationalSimpleOptions = [
    { key: "Optante", value: "1", text: "Optante" },
    { key: "Não optante", value: "0", text: "Não optante" },
  ];
  const hasMunicipalRegistrationOptions = [
    { key: "Sim", value: "Sim", text: "Sim" },
    { key: "Não", value: "Não", text: "Não" },
  ];

  const pjRegistrationFields = [
    { fieldName: "companyName", coolName: "razão social" },
    { fieldName: "telephone", coolName: "telefone" },
    { fieldName: "freeIcms", coolName: "isento ICMS" },
    { fieldName: "nacionalSimpleOptant", coolName: "optante Simples Nacional" },
    {
      fieldName: "hasMunicipalRegistration",
      coolName: "tem inscrição municipal",
    },
    { fieldName: "municipalRegistration", coolName: "inscrição municipal" },
    { fieldName: "stateRegistration", coolName: "inscrição estadual" },
  ];

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("*"),
    telephone: Yup.string()
      .required("*")
      .test("is-phone-number", "*", (value) => phoneValidator(String(value))),
    nacionalSimpleOptant: Yup.string().required("*"),
    municipalRegistration: Yup.string().when("hasMunicipalRegistration", {
      is: "Sim",
      then: Yup.string().required("*"),
      otherwise: Yup.string(),
    }),
    stateRegistration: Yup.string().when("freeIcms", {
      is: "0",
      then: Yup.string().required("*"),
      otherwise: Yup.string(),
    }),
    freeIcms: Yup.string().required("*"),
    hasMunicipalRegistration: Yup.string().required("*"),
  });

  const formik = useFormik({
    initialValues: {
      companyName: registrationData.companyName,
      telephone: registrationData.telephone,
      freeIcms: registrationData.freeIcms,
      nacionalSimpleOptant:
        registrationData.nacionalSimpleOptant === "1" ? "1" : "0",
      hasMunicipalRegistration: registrationData.hasMunicipalRegistration,
      municipalRegistration: registrationData.municipalRegistration,
      stateRegistration: registrationData.stateRegistration,
    },
    onSubmit: (value) => {
      submitInitialData(value);
    },
    validationSchema,
  });

  function submitInitialData(formData: any) {
    setRegistrationData({
      ...formData,
      situation: "complete",
    });
    changeStep("address-data");
  }

  useEffect(() => {
    changeSituation("doing");
    formik.setFieldTouched("telephone");

    // eslint-disable-next-line
  }, []);

  return (
    <Container className="containerFormStep">
      <MyHeader>Dados cadastrais</MyHeader>

      <Container className="inputsContainer">
        <Grid>
          <Grid.Row>
            <Grid.Column computer={10} mobile={16} tablet={16}>
              <MyInput
                id="companyName"
                name="companyName"
                type="text"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Razão social:</span>
                    <MyInputError error={formik.errors.companyName} />
                  </>
                }
                placeholder="Empresa exemplo"
              />
            </Grid.Column>
            <Grid.Column computer={6} mobile={16} tablet={16}>
              <MyInput
                id="telephone"
                name="telephone"
                type="text"
                value={formik.values.telephone}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Telefone:</span>
                    <MyInputError error={formik.errors.telephone} />
                  </>
                }
                placeholder="(99) 999999999"
              >
                <InputMask
                  id="telephone"
                  name="telephone"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.telephone}
                  mask={phoneMask}
                  placeholder="(99) 9 9999 9999"
                />
              </MyInput>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row only="mobile tablet">
            <Grid.Column width={16}>
              <MySelect
                id="hasMunicipalRegistration"
                name="hasMunicipalRegistration"
                type="text"
                options={hasMunicipalRegistrationOptions}
                onChange={(e, { value }) => {
                  formik.setFieldValue("hasMunicipalRegistration", value);
                }}
                selection
                value={formik.values.hasMunicipalRegistration}
                placeholder="Selecione aqui..."
                label={
                  <>
                    <span>Tem Insc. Municipal:</span>
                    <MyInputError
                      error={formik.errors.hasMunicipalRegistration}
                    />
                  </>
                }
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <MySelect
                id="freeIcms"
                name="freeIcms"
                type="text"
                options={freeIcmsOptions}
                onChange={(e, { value }) => {
                  formik.setFieldValue("freeIcms", value);
                }}
                selection
                value={formik.values.freeIcms}
                placeholder="Selecione aqui..."
                label={
                  <>
                    <span>Isento ICMS:</span>
                    <MyInputError error={formik.errors.freeIcms} />
                  </>
                }
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <MySelect
                id="nacionalSimpleOptant"
                name="nacionalSimpleOptant"
                type="text"
                options={nationalSimpleOptions}
                onChange={(e, { value }) => {
                  formik.setFieldValue("nacionalSimpleOptant", value);
                }}
                selection
                value={formik.values.nacionalSimpleOptant}
                placeholder="Selecione aqui..."
                label={
                  <>
                    <span>Simples Nacional:</span>
                    <MyInputError error={formik.errors.nacionalSimpleOptant} />
                  </>
                }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3} only="computer">
            <Grid.Column>
              <MySelect
                id="hasMunicipalRegistration"
                name="hasMunicipalRegistration"
                type="text"
                options={hasMunicipalRegistrationOptions}
                onChange={(e, { value }) => {
                  formik.setFieldValue("hasMunicipalRegistration", value);
                }}
                selection
                value={formik.values.hasMunicipalRegistration}
                placeholder="Selecione aqui..."
                label={
                  <>
                    <span>Tem Insc. Municipal:</span>
                    <MyInputError
                      error={formik.errors.hasMunicipalRegistration}
                    />
                  </>
                }
              />
            </Grid.Column>
            <Grid.Column>
              <MySelect
                id="freeIcms"
                name="freeIcms"
                type="text"
                options={freeIcmsOptions}
                onChange={(e, { value }) => {
                  formik.setFieldValue("freeIcms", value);
                }}
                selection
                value={formik.values.freeIcms}
                placeholder="Selecione aqui..."
                label={
                  <>
                    <span>Isento ICMS:</span>
                    <MyInputError error={formik.errors.freeIcms} />
                  </>
                }
              />
            </Grid.Column>
            <Grid.Column>
              <MySelect
                id="nacionalSimpleOptant"
                name="nacionalSimpleOptant"
                type="text"
                options={nationalSimpleOptions}
                onChange={(e, { value }) => {
                  formik.setFieldValue("nacionalSimpleOptant", value);
                }}
                selection
                value={formik.values.nacionalSimpleOptant}
                placeholder="Selecione aqui..."
                label={
                  <>
                    <span>Simples Nacional:</span>
                    <MyInputError error={formik.errors.nacionalSimpleOptant} />
                  </>
                }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3} only="computer">
            <Grid.Column>
              {formik.values.hasMunicipalRegistration === "Sim" && (
                <MyInput
                  id="municipalRegistration"
                  name="municipalRegistration"
                  type="text"
                  value={formik.values.municipalRegistration}
                  onChange={formik.handleChange}
                  label={
                    <>
                      <span>Inscrição municipal:</span>
                      <MyInputError
                        error={formik.errors.municipalRegistration}
                      />
                    </>
                  }
                  placeholder="9999999999"
                />
              )}
            </Grid.Column>

            <Grid.Column>
              {formik.values.freeIcms === "0" && (
                <MyInput
                  id="stateRegistration"
                  name="stateRegistration"
                  type="text"
                  value={formik.values.stateRegistration}
                  onChange={formik.handleChange}
                  label={
                    <>
                      <span>Inscrição estadual:</span>
                      <MyInputError error={formik.errors.stateRegistration} />
                    </>
                  }
                  placeholder="9999999999"
                />
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Column only="mobile tablet" width={16}>
            {formik.values.hasMunicipalRegistration === "Sim" && (
              <MyInput
                id="municipalRegistration"
                name="municipalRegistration"
                type="text"
                value={formik.values.municipalRegistration}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Inscrição municipal:</span>
                    <MyInputError error={formik.errors.municipalRegistration} />
                  </>
                }
                placeholder="9999999999"
              />
            )}
          </Grid.Column>

          <Grid.Column only="mobile tablet" width={16}>
            {formik.values.freeIcms === "0" && (
              <MyInput
                id="stateRegistration"
                name="stateRegistration"
                type="text"
                value={formik.values.stateRegistration}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Inscrição estadual:</span>
                    <MyInputError error={formik.errors.stateRegistration} />
                  </>
                }
                placeholder="9999999999"
              />
            )}
          </Grid.Column>
        </Grid>
      </Container>

      <Container className="containerCommandButtons">
        <MyButton
          icon
          onClick={() => {
            changeSituation("to-do");
            changeStep("initial-data");
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          VOLTAR
        </MyButton>
        <MyButton
          icon
          onClick={() =>
            formik.isValid
              ? formik.submitForm()
              : returnFormMessage(
                  formik.isValid,
                  formik.errors,
                  formik.values,
                  pjRegistrationFields
                )
                  .then((message) => toast.error(message))
                  .catch(() => {
                    toast.error(
                      "Algum campo não possui o valor que esperamos."
                    );
                  })
          }
        >
          AVANÇAR
          <FontAwesomeIcon icon={faArrowRight} />
        </MyButton>
      </Container>
    </Container>
  );
}

export default PjRegistrationData;
