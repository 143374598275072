import React from "react";

// styles
import { Container } from "semantic-ui-react";
import styles from "../styles/components/MyFooter.module.scss";

const MyHeaderLogo = () => {
  return (
    <Container textAlign="center" className={styles.footer}>
      <a
        href="https://www.jdrel.com.br/solucoes/jdrel-conversor/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Precisa de ajuda? Clique aqui.
      </a>
    </Container>
  );
};

export default MyHeaderLogo;
