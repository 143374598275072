import React from "react";
import { Select, SelectProps } from "semantic-ui-react";
import styles from "../styles/components/MySelect.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

interface MySelectProps extends SelectProps {
  name: string;
  label: any;
  type: string;
  options: Array<OptionsModel>;
}

interface OptionsModel {
  value: string;
  text: string;
}

const MySelect = ({ label, options, ...rest }: MySelectProps) => {
  return (
    <>
      <div role="menuitem">
        <label>{label}</label>
        <Select
          fluid
          options={options}
          className={styles.mySelect}
          icon={
            <FontAwesomeIcon icon={faAngleDown} className={styles.selectIcon} />
          }
          {...rest}
        />
      </div>
    </>
  );
};

export default MySelect;
