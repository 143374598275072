import React from "react";

import { Button, StrictButtonProps } from "semantic-ui-react";
import styles from "../styles/components/MyButton.module.scss";

interface MyButtonProps extends StrictButtonProps {
  children?: any;
  disabled?: boolean;
  small?: boolean;
  danger?: boolean;
  tooltip?: string;
  highlight?: boolean;
  onlyIcon?: boolean;
  secondary?: boolean;
  info?: string;
  rest?: string;
}

const MyButton = ({ children, ...rest }: MyButtonProps) => {
  const {
    disabled,
    small,
    tooltip,
    compact,
    danger,
    highlight,
    secondary,
    info,
    onlyIcon,
  } = {
    ...rest,
  };
  /** Caso use small é necessário setar labelPosition='right' no componente; */
  return (
    <Button
      {...rest}
      className={[
        styles.button,
        onlyIcon ? styles.onlyIcon : "", // Declare Padding equal to contain icon
        danger ? styles.danger : "",
        disabled ? styles.disabled : "", // toggles hidden-visible
        small ? styles.small : "", // collapsible button
        tooltip ? styles.tooltip : "", // adds a tooltip for the button
        compact ? styles.compact : "", // use compact styling for the button
        highlight ? styles.highlight : "", // use highlight styling for the button
        secondary ? styles.secondary : "", //
        info ? styles.info : "", // use info styling for the button #FFFFFF
      ].join(" ")}
    >
      {children}
    </Button>
  );
};

export default MyButton;
