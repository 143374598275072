import React, { useEffect } from "react";

// contexts / providers / services
import { useProductContext } from "../contexts/ProductContext";
import { useRegistrationContext } from "../contexts/RegistrationContext";

// form
import { useFormik } from "formik";
import * as Yup from "yup";
import { returnFormMessage } from "../utils/formMessages";
import { phoneValidator, phoneMask } from "../utils/formValidators";
import InputMask from "react-text-mask";

// stylized components
import MyHeader from "./MyHeader";
import MyInput from "./MyInput";
import MyButton from "./MyButton";
import MyInputError from "./MyInputError";

// styles, etc
import { Container } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function PfRegistrationData() {
  const { setRegistrationData, changeSituation, registrationData } =
    useRegistrationContext();
  const { changeStep } = useProductContext();

  const pfRegistrationFields = [
    { fieldName: "companyName", coolName: "razão social" },
    { fieldName: "telephone", coolName: "telefone" },
  ];

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("*").min(3, "*"),
    telephone: Yup.string()
      .required("*")
      .test("is-phone-number", "*", (value) => phoneValidator(String(value))),
  });

  const formik = useFormik({
    initialValues: {
      companyName: registrationData.companyName,
      telephone: registrationData.telephone,
    },
    onSubmit: (formData) => {
      submitRegistrationData(formData);
    },
    validationSchema,
  });

  function submitRegistrationData(formData: any) {
    setRegistrationData({ ...formData, situation: "complete" });
    changeStep("address-data");
  }

  useEffect(() => {
    changeSituation("doing");
    formik.setFieldTouched("companyName");

    // eslint-disable-next-line
  }, []);

  return (
    <Container className="containerFormStep">
      <MyHeader>Dados cadastrais</MyHeader>

      <Container className="inputsContainer">
        <MyInput
          id="companyName"
          name="companyName"
          type="text"
          value={formik.values.companyName}
          onChange={formik.handleChange}
          placeholder="Maria da Silva"
          label={
            <>
              <span>Seu nome:</span>
              <MyInputError error={formik.errors.companyName} />
            </>
          }
        />

        <MyInput
          name="telephone"
          type="text"
          label={
            <>
              <span>Seu telefone:</span>
              <MyInputError error={formik.errors.telephone} />
            </>
          }
          value={formik.values.telephone}
        >
          <InputMask
            id="telephone"
            name="telephone"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.telephone}
            mask={phoneMask}
            placeholder="(99) 999999999"
          />
        </MyInput>
      </Container>

      <Container className="containerCommandButtons">
        <MyButton
          icon
          onClick={() => {
            changeSituation("to-do");
            changeStep("initial-data");
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          VOLTAR
        </MyButton>
        <MyButton
          icon
          onClick={() =>
            formik.isValid
              ? formik.submitForm()
              : returnFormMessage(
                  formik.isValid,
                  formik.errors,
                  formik.values,
                  pfRegistrationFields
                )
                  .then((message) => toast.error(message))
                  .catch(() => {
                    toast.error(
                      "Algum campo não possui o valor que esperamos."
                    );
                  })
          }
        >
          AVANÇAR
          <FontAwesomeIcon icon={faArrowRight} />
        </MyButton>
      </Container>
    </Container>
  );
}

export default PfRegistrationData;
