import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";

// styles
import "react-toastify/dist/ReactToastify.min.css";
import "semantic-ui-css/semantic.min.css";
import "./styles/global.scss";
import "./styles/semantic-changes.scss";
import { ToastContainer } from "react-toastify";

// contexts/providers
import { CheckoutContextsProvider } from "./utils/CheckoutContextsProvider";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
  <>
    <CheckoutContextsProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)}
      >
        <Routes />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
        />
      </GoogleReCaptchaProvider>
    </CheckoutContextsProvider>
  </>,
  document.getElementById("root")
);
