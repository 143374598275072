import React, { useState, useEffect } from "react";

// contexts / providers / services
import { SellerModel, OptionsModel } from "../models/InitialModel";
import { useRegistrationContext } from "../contexts/RegistrationContext";
import { useProductContext } from "../contexts/ProductContext";
import { useInitialContext } from "../contexts/InitialContext";
import { ServiceAPI } from "../services/api";

// form
import { useFormik } from "formik";
import * as Yup from "yup";
import isCPF from "../utils/cpfOrCnpjValidation";
import { returnFormMessage } from "../utils/formMessages";
import { cpfMask, cnpjMask } from "../utils/formValidators";
import InputMask from "react-text-mask";

// stylized components
import MyHeader from "./MyHeader";
import MyInput from "./MyInput";
import MyButton from "./MyButton";
import MyInputError from "./MyInputError";
import MySelect from "./MySelect";

// styles, etc
import { Container, Modal, Loader } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function InitialData() {
  const [sellersOptions, setSellersOptions] = useState<Array<OptionsModel>>([]);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const { initialData, changeSituation, changeInitialData } =
    useInitialContext();
  const { setCompanyData } = useRegistrationContext();
  const { isLoading, changeStep } = useProductContext();

  const personTypeOptions = [
    { key: "Pessoa física", value: "Pessoa física", text: "Pessoa física" },
    {
      key: "Pessoa jurídica",
      value: "Pessoa jurídica",
      text: "Pessoa jurídica",
    },
  ];

  const initialDataFields = [
    { fieldName: "seller", coolName: "vendedor" },
    { fieldName: "personType", coolName: "pessoa física ou jurídica" },
  ];

  const validationSchema = Yup.object().shape({
    cpfOrCnpj: Yup.string()
      .required("*")
      .test("is-cpf-or-cnpj", "*", (value) => isCPF(value)),
    seller: Yup.string().required("*"),
    personType: Yup.string().required("*"),
  });

  const formik = useFormik({
    initialValues: {
      seller: initialData.seller.st_nome_grp,
      cpfOrCnpj: initialData.cpfOrCnpj,
      personType: initialData.personType,
    },
    onSubmit: (value) => {
      formik.isValid ? submitInitialData(value) : toast.error(value);
    },
    validationSchema,
  });

  async function submitInitialData(formData: any) {
    changeInitialData(formData);

    const cleanCpfOrCnpj = formData.cpfOrCnpj.replace(/\D/g, "");

    if (cleanCpfOrCnpj.length === 11) {
      changeStep("pf-registration-data");
    } else {
      setCompanyData(cleanCpfOrCnpj);
    }
  }

  useEffect(() => {
    if (isLoading) {
      setOpenLoadingModal(true);
    } else {
      setOpenLoadingModal(false);
    }
  }, [isLoading]);

  useEffect(() => {
    changeSituation("doing");
    formik.setFieldTouched("cpfOrCnpj");

    async function loadSellers() {
      try {
        let sellersOptionsArray: Array<OptionsModel> = [];
        const apiData: Array<SellerModel> = await ServiceAPI.get(
          "/sellers?product=Conversor"
        ).then((response) => {
          return response.data;
        });

        apiData.forEach((seller) => {
          const optionObject = {
            key: seller.st_nome_grp,
            value: seller.st_nome_grp,
            text: seller.st_nome_grp,
          };

          return sellersOptionsArray.push(optionObject);
        });

        sellersOptionsArray.sort((a, b) =>
          a.key > b.key ? 1 : b.key > a.key ? -1 : 0
        );

        setSellersOptions(sellersOptionsArray);
      } catch (err) {
        console.error(err);
        toast.error("Não conseguimos carregar os vendedores.");
      }
    }

    loadSellers();
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="containerFormStep">
      <MyHeader>Dados iniciais</MyHeader>

      <Container className="inputsContainer">
        <MySelect
          id="personType"
          name="personType"
          type="text"
          options={personTypeOptions}
          onChange={(e, { value }) => formik.setFieldValue("personType", value)}
          selection
          value={formik.values.personType}
          placeholder="Selecione aqui..."
          label={
            <>
              <span>Eu sou:</span>
              <MyInputError error={formik.errors.personType} />
            </>
          }
        />

        {formik.values.personType === "Pessoa física" ? (
          <MyInput
            name="cpfOrCnpj"
            type="text"
            label={
              <>
                <span>Seu CPF:</span>
                <MyInputError error={formik.errors.cpfOrCnpj} />
              </>
            }
            value={formik.values.cpfOrCnpj}
          >
            <InputMask
              id="cpfOrCnpj"
              name="cpfOrCnpj"
              type="text"
              placeholder="000.000.000-00"
              mask={cpfMask}
              value={formik.values.cpfOrCnpj}
              onChange={formik.handleChange}
            />
          </MyInput>
        ) : (
          <MyInput
            name="cpfOrCnpj"
            type="text"
            label={
              <>
                <span>Seu CNPJ:</span>
                <MyInputError error={formik.errors.cpfOrCnpj} />
              </>
            }
            value={formik.values.cpfOrCnpj}
          >
            <InputMask
              id="cpfOrCnpj"
              name="cpfOrCnpj"
              type="text"
              placeholder="00.000.000/0000-00"
              mask={cnpjMask}
              value={formik.values.cpfOrCnpj}
              onChange={formik.handleChange}
            />
          </MyInput>
        )}

        <MySelect
          id="seller"
          name="seller"
          type="text"
          options={sellersOptions}
          onChange={(e, { value }) => formik.setFieldValue("seller", value)}
          selection
          value={formik.values.seller}
          placeholder="Selecione aqui..."
          label={
            <>
              <span>Quem foi seu consultor:</span>
              <MyInputError error={formik.errors.seller} />
            </>
          }
        />
      </Container>

      <Container className="containerCommandButtons">
        <MyButton disabled icon>
          <FontAwesomeIcon icon={faArrowLeft} />
          VOLTAR
        </MyButton>
        <Modal
          basic
          open={openLoadingModal}
          size="small"
          trigger={
            <MyButton
              icon
              onClick={() =>
                formik.isValid
                  ? formik.submitForm()
                  : returnFormMessage(
                      formik.isValid,
                      formik.errors,
                      formik.values,
                      initialDataFields
                    )
                      .then((message) => toast.error(message))
                      .catch(() => {
                        toast.error(
                          "Algum campo não possui o valor que esperamos."
                        );
                      })
              }
            >
              AVANÇAR
              <FontAwesomeIcon icon={faArrowRight} />
            </MyButton>
          }
        >
          <Modal.Content>
            <Loader indeterminate>
              Aguarde, estamos carregando seus dados...
            </Loader>
          </Modal.Content>
        </Modal>
      </Container>
    </Container>
  );
}

export default InitialData;
