import React from "react";
import { Form, InputProps } from "semantic-ui-react";

import PropTypes from "prop-types";

import styles from "../styles/components/MyInput.module.scss";

interface MyInputProps extends InputProps {
  name: string;
  type: string;
  label: any;
}

const MyInput = ({ ...rest }: MyInputProps) => (
  <Form.Input fluid className={styles.myInput} {...rest} />
);

export default MyInput;

MyInput.propTypes = {
  value: PropTypes.string,
};

MyInput.defaultProps = {
  value: PropTypes.string,
};
