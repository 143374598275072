import { createContext, ReactNode, useState, useContext } from "react";

import { PaymentModel } from "../models/PaymentModel";

interface PaymentContextModel {
  paymentData: PaymentModel;
  setPaymentData: (paymentData: PaymentModel) => void;
  changeSituation: (situation: string) => void;
}

interface PaymentContextProps {
  children?: ReactNode;
}

const PaymentContext = createContext({} as PaymentContextModel);

export function PaymentContextProvider({ children }: PaymentContextProps) {
  const [paymentData, setPaymentData] = useState<PaymentModel>({
    creditCardNumber: "",
    creditCardName: "",
    creditCardCvv: "",
    creditCardValidity: "",
    creditCardFlag: "",
    bankSlip: false,
    policyPrivacy: false,
    situation: "to-do",
  });

  function changeSituation(situation: string) {
    setPaymentData({
      ...paymentData,
      situation: situation,
    });
  }

  return (
    <PaymentContext.Provider
      value={{
        paymentData,
        setPaymentData,
        changeSituation,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

export function usePaymentContext() {
  const paymentContext = useContext(PaymentContext);
  const { paymentData, setPaymentData, changeSituation } = paymentContext;

  return {
    paymentData,
    setPaymentData,
    changeSituation,
  };
}
