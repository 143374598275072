import React, { useEffect, useState } from "react";

// contexts / providers / services
import { useProductContext } from "../contexts/ProductContext";
import { useInitialContext } from "../contexts/InitialContext";
import { useAddressContext } from "../contexts/AddressContext";
import { ViaCepAPI } from "../services/api";

// models
import { ViaCepModel } from "../models/AddressModel";

// form
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-text-mask";
import { returnFormMessage } from "../utils/formMessages";
import { cepMask } from "../utils/formValidators";

// stylized components
import MyHeader from "./MyHeader";
import MyInput from "./MyInput";
import MyButton from "./MyButton";
import MyInputError from "./MyInputError";

// styles, etc
import { Container, Grid } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import styles from "../styles/components/AddressData.module.scss";
import { toast } from "react-toastify";

function AddressData() {
  const { addressData, setAddressData, changeSituation } = useAddressContext();
  const { initialData } = useInitialContext();
  const { changeStep } = useProductContext();

  const [isLoadingAddress, setIsLoadingAddress] = useState(false);

  const addressDataFields = [
    { fieldName: "cep", coolName: "CEP" },
    { fieldName: "address", coolName: "endereço" },
    { fieldName: "number", coolName: "número" },
    { fieldName: "complement", coolName: "complemento" },
    { fieldName: "city", coolName: "cidade" },
    { fieldName: "district", coolName: "bairro" },
    { fieldName: "state", coolName: "UF" },
  ];

  const validationSchema = Yup.object().shape({
    cep: Yup.string().required("*"),
    address: Yup.string().required("*"),
    number: Yup.string().required("*"),
    complement: Yup.string(),
    city: Yup.string().required("*"),
    district: Yup.string().required("*"),
    state: Yup.string().required("*"),
  });

  const formik = useFormik({
    initialValues: {
      cep: addressData.cep,
      address: addressData.cep,
      number: addressData.number,
      complement: addressData.complement,
      city: addressData.city,
      district: addressData.district,
      state: addressData.state,
    },
    onSubmit: (formData) => {
      submitAddressData(formData);
    },
    validationSchema,
  });

  function submitAddressData(formData: any) {
    setAddressData({
      ...formData,
      situation: "complete",
    });
    changeStep("access-data");
  }

  function backStep() {
    changeSituation("to-do");
    const cleanCpfOrCnpj = initialData.cpfOrCnpj.replace(/\D/g, "");
    if (cleanCpfOrCnpj.length === 11) {
      return changeStep("pf-registration-data");
    } else {
      return changeStep("pj-registration-data");
    }
  }

  async function searchCep(e: any) {
    if (String(e).length >= 8) {
      let cep;

      if (e.target) {
        const { value } = e.target;
        cep = String(value)?.replace(/[^0-9]/g, "");
      } else {
        cep = String(e).replace(/\D/g, "");
      }

      if (cep.length !== 8) {
        return;
      }

      try {
        setIsLoadingAddress(true);
        const viaCepRes: ViaCepModel = await ViaCepAPI.get(`${cep}/json`);

        formik.setValues({
          ...formik.values,
          cep: cep,
          address: viaCepRes.data.logradouro,
          city: viaCepRes.data.localidade,
          district: viaCepRes.data.bairro,
          state: viaCepRes.data.uf,
        });
        setIsLoadingAddress(false);
      } catch {
        setIsLoadingAddress(false);
        toast.error("Não encontramos dados para o CEP que você digitou");
      }
    }
  }

  useEffect(() => {
    changeSituation("doing");
    formik.setFieldTouched("cep");

    searchCep(String(addressData.cep));
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="containerFormStep">
      <MyHeader>Endereço</MyHeader>

      <Container className="inputsContainer">
        <Grid className={styles.grid}>
          <Grid.Row>
            {/* ROW NUMBER 1 */}
            <Grid.Column computer={5} mobile={16} tablet={16}>
              <MyInput
                name="cep"
                type="text"
                label={
                  <>
                    <span>CEP:</span>
                    <MyInputError error={formik.errors.cep} />
                  </>
                }
                loading={isLoadingAddress}
                value={formik.values.cep}
              >
                <InputMask
                  id="cep"
                  name="cep"
                  type="text"
                  value={formik.values.cep}
                  onChange={(value) => {
                    searchCep(value);
                    formik.handleChange(value);
                  }}
                  mask={cepMask}
                  placeholder="00000-000"
                ></InputMask>
              </MyInput>
            </Grid.Column>
            <Grid.Column computer={11} mobile={16} tablet={16}>
              <MyInput
                id="address"
                name="address"
                type="text"
                value={formik.values.address}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Endereço:</span>
                    <MyInputError error={formik.errors.address} />
                  </>
                }
                loading={isLoadingAddress}
                placeholder="Rua: João da Silva"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            {/* ROW NUMBER 2 */}
            <Grid.Column computer={4} mobile={16} tablet={16}>
              <MyInput
                id="number"
                name="number"
                type="text"
                value={formik.values.number}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Número:</span>
                    <MyInputError error={formik.errors.number} />
                  </>
                }
                loading={isLoadingAddress}
                placeholder="999"
              />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} tablet={16}>
              <MyInput
                id="complement"
                name="complement"
                type="text"
                value={formik.values.complement}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Complemento:</span>
                    <MyInputError error={formik.errors.complement} />
                  </>
                }
                loading={isLoadingAddress}
                placeholder="Sala 101 e 102"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            {/* ROW NUMBER 3 */}
            <Grid.Column computer={7} mobile={16} tablet={16}>
              <MyInput
                id="city"
                name="city"
                type="text"
                value={formik.values.city}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Cidade:</span>
                    <MyInputError error={formik.errors.city} />
                  </>
                }
                loading={isLoadingAddress}
                placeholder="São José"
              />
            </Grid.Column>
            <Grid.Column computer={6} mobile={16} tablet={16}>
              <MyInput
                id="district"
                name="district"
                type="text"
                value={formik.values.district}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>Bairro:</span>
                    <MyInputError error={formik.errors.district} />
                  </>
                }
                loading={isLoadingAddress}
                placeholder="Centro"
              />
              {/* <MyInputError /> */}
            </Grid.Column>
            <Grid.Column computer={3} mobile={16} tablet={16}>
              <MyInput
                id="state"
                name="state"
                type="text"
                value={formik.values.state}
                onChange={formik.handleChange}
                label={
                  <>
                    <span>UF:</span>
                    <MyInputError error={formik.errors.state} />
                  </>
                }
                loading={isLoadingAddress}
                placeholder="SC"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Container className="containerCommandButtons">
        <MyButton icon onClick={() => backStep()}>
          <FontAwesomeIcon icon={faArrowLeft} />
          VOLTAR
        </MyButton>
        <MyButton
          icon
          onClick={() =>
            formik.isValid
              ? formik.submitForm()
              : returnFormMessage(
                  formik.isValid,
                  formik.errors,
                  formik.values,
                  addressDataFields
                )
                  .then((message) => toast.error(message))
                  .catch(() => {
                    toast.error(
                      "Algum campo não possui o valor que esperamos."
                    );
                  })
          }
        >
          AVANÇAR
          <FontAwesomeIcon icon={faArrowRight} />
        </MyButton>
      </Container>
    </Container>
  );
}

export default AddressData;
