import { createContext, ReactNode, useState, useContext } from "react";

import { AddressModel } from "../models/AddressModel";

interface AddressContextModel {
  addressData: AddressModel;
  setAddressData: (addressData: AddressModel) => void;
  changeSituation: (situation: string) => void;
}

interface AddressContextProps {
  children?: ReactNode;
}

const AddressContext = createContext({} as AddressContextModel);

export function AddressContextProvider({
  children,
  ...rest
}: AddressContextProps) {
  const [addressData, setAddressData] = useState<AddressModel>({
    cep: "",
    address: "",
    number: "",
    complement: "",
    city: "",
    district: "",
    state: "",
    situation: "to-do",
  });

  function changeSituation(situation: string) {
    setAddressData({
      ...addressData,
      situation: situation,
    });
  }

  return (
    <AddressContext.Provider
      value={{
        addressData,
        setAddressData,
        changeSituation,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export function useAddressContext() {
  const addressContext = useContext(AddressContext);
  const { addressData, setAddressData, changeSituation } = addressContext;

  return {
    addressData,
    setAddressData,
    changeSituation,
  };
}
