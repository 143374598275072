import React, { useEffect } from "react";

import { RouteComponentProps } from "react-router-dom";
import { useHistory } from "react-router-dom";

// components
import MyHeaderLogo from "../components/MyHeaderLogo";
import SimplePurchaseSummary from "../components/SimplePurchaseSummary";

// contexts
import { useCheckoutFormContext } from "../contexts/CheckoutFormContext";

//styles
import { Container } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import styles from "../styles/pages/Success.module.scss";
import MyButton from "../components/MyButton";

const Success: React.FC<RouteComponentProps> = ({ history }) => {
  const { isPaid } = useCheckoutFormContext();

  const link_boleto = history.location.state;

  const reactHistory = useHistory();

  useEffect(() => {
    if (!isPaid) {
      reactHistory.push("/rota-invalida");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container className={styles.successPageContainer} centered="center">
      <MyHeaderLogo />

      <Container className={styles.containerSuccess}>
        <Container textAlign="center" className={styles.successMessage}>
          <FontAwesomeIcon icon={faCheckCircle} size="5x" />
          <h2>Compra realizada!</h2>
          <h4>
            Após a compensação do pagamento nossa equipe enviará as orientações
            para o uso da ferramenta.
          </h4>
        </Container>

        <Container className={styles.purchaseSummary}>
          <SimplePurchaseSummary />
        </Container>

        {link_boleto && (
          <Container textAlign="center">
            <MyButton>
              <a
                className="boleto"
                href={`${link_boleto}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                VISUALIZAR BOLETO
                <FontAwesomeIcon icon={faFileInvoiceDollar} />
              </a>
            </MyButton>
          </Container>
        )}
      </Container>
    </Container>
  );
};

export default Success;
