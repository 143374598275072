export default function isCPF(cpfOrCnpj: any) {
  if (cpfOrCnpj) {
    cpfOrCnpj = cpfOrCnpj.replace(/\D/g, "");
    if (cpfOrCnpj.length === 11) {
      if (!cpfOrCnpj) {
        return true;
      }

      let clearCpf = "";
      if (cpfOrCnpj) {
        clearCpf = cpfOrCnpj.replace(/[^\d]/g, "");
      } else {
        return false;
      }

      let sum = 0;
      let rest;

      if (
        clearCpf.length !== 11 ||
        clearCpf === "00000000000" ||
        clearCpf === "11111111111" ||
        clearCpf === "22222222222" ||
        clearCpf === "33333333333" ||
        clearCpf === "44444444444" ||
        clearCpf === "55555555555" ||
        clearCpf === "66666666666" ||
        clearCpf === "77777777777" ||
        clearCpf === "88888888888" ||
        clearCpf === "99999999999"
      ) {
        return false;
      }

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(clearCpf.substring(i - 1, i), 10) * (11 - i);
      }

      rest = (sum * 10) % 11;

      if (rest === 10 || rest === 11) {
        rest = 0;
      }

      if (rest !== parseInt(clearCpf.substring(9, 10), 10)) {
        return false;
      }

      sum = 0;

      for (let i = 1; i <= 10; i++) {
        sum += parseInt(clearCpf.substring(i - 1, i), 10) * (12 - i);
      }

      rest = (sum * 10) % 11;

      if (rest === 10 || rest === 11) {
        rest = 0;
      }

      if (rest !== parseInt(clearCpf.substring(10, 11), 10)) {
        return false;
      }

      return true;
    } else {
      // eslint-disable-next-line
      if (cpfOrCnpj == "") return false;

      // eslint-disable-next-line
      if (cpfOrCnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        // eslint-disable-next-line
        cpfOrCnpj == "00000000000000" ||
        // eslint-disable-next-line
        cpfOrCnpj == "11111111111111" ||
        // eslint-disable-next-line
        cpfOrCnpj == "22222222222222" ||
        // eslint-disable-next-line
        cpfOrCnpj == "33333333333333" ||
        // eslint-disable-next-line
        cpfOrCnpj == "44444444444444" ||
        // eslint-disable-next-line
        cpfOrCnpj == "55555555555555" ||
        // eslint-disable-next-line
        cpfOrCnpj == "66666666666666" ||
        // eslint-disable-next-line
        cpfOrCnpj == "77777777777777" ||
        // eslint-disable-next-line
        cpfOrCnpj == "88888888888888" ||
        // eslint-disable-next-line
        cpfOrCnpj == "99999999999999"
      )
        return false;

      // Valida DVs
      let tamanho = cpfOrCnpj.length - 2;
      let numeros = cpfOrCnpj.substring(0, tamanho);
      let digitos = cpfOrCnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      // eslint-disable-next-line
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cpfOrCnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      // eslint-disable-next-line
      if (resultado != digitos.charAt(1)) return false;

      return true;
    }
  } else {
    return true;
  }
}
