import { AccessContextProvider } from "../contexts/AccessContext";
import { AddressContextProvider } from "../contexts/AddressContext";
import { InitialContextProvider } from "../contexts/InitialContext";
import { RegistrationContextProvider } from "../contexts/RegistrationContext";
import { PaymentContextProvider } from "../contexts/PaymentContext";
import { CheckoutFormContextProvider } from "../contexts/CheckoutFormContext";
import { ProductContextProvider } from "../contexts/ProductContext";

import { combineComponents } from "./combineComponents";

const providers = [
  ProductContextProvider,
  PaymentContextProvider,
  AccessContextProvider,
  AddressContextProvider,
  RegistrationContextProvider,
  InitialContextProvider,
  CheckoutFormContextProvider,
];

export const CheckoutContextsProvider = combineComponents(...providers);
