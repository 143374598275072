import React, { useState, useEffect } from "react";

// contexts / providers / services / utils
import { useProductContext } from "../contexts/ProductContext";
import { usePaymentContext } from "../contexts/PaymentContext";

// form
import { useFormik } from "formik";
import * as Yup from "yup";
import { moneyFormat } from "../utils/moneyFormat";

//my components
import MyInput from "./MyInput";
import MyButton from "./MyButton";
import MyHeader from "./MyHeader";

// styles
import { Container } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import styles from "../styles/components/CouponData.module.scss";

function Coupon() {
  const {
    applyCoupon,
    activeCoupon,
    changeStep,
    changeCouponSituation,
    productData,
  } = useProductContext();
  const { setPaymentData, paymentData } = usePaymentContext();

  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);

  const validationSchema = Yup.object().shape({
    coupon: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      coupon: "",
    },
    onSubmit: (value) => {
      setIsLoadingCoupon(true);
      applyCoupon(value.coupon)
        .then(() => setIsLoadingCoupon(false))
        .catch((message) => {
          setIsLoadingCoupon(false);
          toast.error(message);
        });
    },
    validationSchema,
  });

  useEffect(() => {
    changeCouponSituation("doing");
    setPaymentData({
      ...paymentData,
      situation: "to-do",
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="containerFormStep">
      <MyHeader>Cupom</MyHeader>

      {activeCoupon && (
        <Container className={styles.verifiedCoupon}>
          <FontAwesomeIcon icon={faCheckCircle} size="5x" />
          <h2>Cupom verificado!</h2>
          <h4>
            Você recebeu {moneyFormat(Number(productData.desconto))} de
            desconto.
          </h4>
        </Container>
      )}
      {!activeCoupon && (
        <Container className={styles.notVerifiedCoupon} textAlign="center">
          <MyInput
            fluid
            id="coupon"
            name="coupon"
            type="text"
            label={<p>Caso tenha um cupom de desconto, informe aqui:</p>}
            placeholder="Código do cupom"
            value={formik.values.coupon}
            onChange={formik.handleChange}
            loading={isLoadingCoupon}
          />
        </Container>
      )}

      {activeCoupon ? (
        <Container className="containerCommandButtons">
          <MyButton
            icon
            onClick={() => {
              changeStep("access-data");
              changeCouponSituation("to-do");
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            VOLTAR
          </MyButton>
          <MyButton
            icon
            onClick={() => {
              changeStep("payment-data");
              changeCouponSituation("complete");
            }}
          >
            OK
            <FontAwesomeIcon icon={faArrowRight} />
          </MyButton>
        </Container>
      ) : (
        <Container className="containerCommandButtons">
          <MyButton
            icon
            onClick={() => {
              changeStep("access-data");
              changeCouponSituation("to-do");
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            VOLTAR
          </MyButton>
          <MyButton
            icon
            onClick={() => {
              if (formik.values.coupon.length > 0) {
                formik.submitForm();
              } else {
                changeStep("payment-data");
                changeCouponSituation("complete");
              }
            }}
          >
            AVANÇAR
            <FontAwesomeIcon icon={faArrowRight} />
          </MyButton>
        </Container>
      )}
    </Container>
  );
}

export default Coupon;
