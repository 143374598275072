import React from "react";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useHistory } from "react-router-dom";

// react function components
import PurchaseSummary from "../components/PurchaseSummary";
import InitialData from "../components/InitialData";
import PjRegistrationData from "../components/PjRegistrationData";
import PfRegistrationData from "../components/PfRegistrationData";
import AddressData from "../components/AddressData";
import AccessData from "../components/AccessData";
import Coupon from "../components/CouponData";
import PaymentData from "../components/PaymentData";
import Steps from "../components/Steps";

// stylized components
import MyHeaderLogo from "../components/MyHeaderLogo";
import MyFooter from "../components/MyFooter";

// contexts
import { useProductContext } from "../contexts/ProductContext";
import { useCheckoutFormContext } from "../contexts/CheckoutFormContext";

// styles
import { Container, Grid } from "semantic-ui-react";
import styles from "../styles/pages/Form.module.scss";
import { toast } from "react-toastify";

const Form = () => {
  const { formStep } = useProductContext();
  const { verifyRecaptcha } = useCheckoutFormContext();

  const history = useHistory();

  function defineStep() {
    switch (formStep) {
      case "initial-data":
        return <InitialData />;
      case "pf-registration-data":
        return <PfRegistrationData />;
      case "pj-registration-data":
        return <PjRegistrationData />;
      case "address-data":
        return <AddressData />;
      case "access-data":
        return <AccessData />;
      case "coupon-data":
        return <Coupon />;
      case "payment-data":
        return <PaymentData />;
    }
  }

  return (
    <Container className={styles.container}>
      <MyHeaderLogo></MyHeaderLogo>

      <Steps />

      <Grid textAlign="center" className={styles.equalHeight}>
        <Grid.Row>
          <Grid.Column
            computer={7}
            tablet={16}
            mobile={16}
            className={styles.gridStep}
          >
            {defineStep()}
          </Grid.Column>
          <Grid.Column
            only="computer"
            computer={5}
            className={styles.gridPurchase}
          >
            <PurchaseSummary />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <GoogleReCaptcha
        onVerify={(e) => {
          verifyRecaptcha(e)
            .then(() => {})
            .catch((err) => {
              toast.error(err);
              history.push("/recaptcha-invalido");
            });
        }}
      />

      <MyFooter></MyFooter>
    </Container>
  );
};

export default Form;
