import React from "react";

// styles
import { Header, Image, Grid } from "semantic-ui-react";
import styles from "../styles/components/MyHeaderLogo.module.scss";
const logoConversor =
  "https://storage.googleapis.com/checkout-images/logo-conversor.svg";

const MyHeaderLogo = () => {
  return (
    <>
      <Grid>
        <Grid.Column
          only="computer tablet"
          textAlign="center"
          className={styles.myHeaderComputer}
        >
          <Header>
            <Image src={logoConversor} />
          </Header>
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column
          only="mobile"
          textAlign="center"
          className={styles.myHeaderMobile}
        >
          <Header>
            <Image src={logoConversor} />
          </Header>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default MyHeaderLogo;
