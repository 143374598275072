import React from "react";
import { Container } from "semantic-ui-react";
import styles from "../styles/pages/NotFound.module.scss";

import MyButton from "../components/MyButton";

function NotFound() {
  return (
    <Container textAlign="center" className={styles.notFoundContainer}>
      <div>
        <h1>Erro 404</h1>
        <h4>Ainda não criamos a página que você procura.</h4>
        <MyButton>
          <a href="https://checkout.jdrel.com.br/">Ir para a home</a>
        </MyButton>
      </div>
    </Container>
  );
}

export default NotFound;
