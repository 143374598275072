function formatStringErrors(errorsString: string) {
  if (errorsString.split(", ").length === 2) {
    errorsString = errorsString.substring(0, errorsString.length - 2);
    return `Há algo errado com o campo ${errorsString}.`;
  }
  if (errorsString.split(", ").length === 3) {
    errorsString = errorsString.substring(0, errorsString.length - 2);
    errorsString = errorsString.replace(", ", " e ");
    return `Há algo errado com os campos: ${errorsString}.`;
  } else {
    errorsString = errorsString.substring(0, errorsString.length - 2);
    return `Há algo errado com os campos: ${errorsString}.`;
  }
}

interface componentFields {
  fieldName: string;
  coolName: string;
}

export function returnFormMessage(
  formikValid: any,
  formikErrors: any,
  formikValues: any,
  component: Array<componentFields>
): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    if (!formikValid) {
      const errorsArray = Object.entries(formikErrors);
      errorsArray.forEach((errorName) => {
        if (errorName[0] === "cpfOrCnpj") {
          if (formikValues.personType) {
            if (formikValues.personType === "Pessoa jurídica") {
              errorName[0] = "CNPJ";
            } else {
              errorName[0] = "CPF";
            }
          } else {
            errorName[0] = "CPF ou CNPJ";
          }
        }

        component.forEach((field) => {
          switch (errorName[0]) {
            case field.fieldName:
              errorName[0] = field.coolName;
              break;
          }
        });
      });

      let arrayFieldErrors: Array<string> = [];

      errorsArray.forEach((formikErrors) => {
        arrayFieldErrors.push(formikErrors[0]);
      });

      let stringFieldErrors: string = "";

      arrayFieldErrors.forEach((fieldError) => {
        stringFieldErrors += `${fieldError}, `;
      });

      resolve(formatStringErrors(stringFieldErrors));
    } else resolve("Existem campos incorretos, mas não os identificamos");
  });
}
