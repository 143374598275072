import { createContext, ReactNode, useState, useContext } from "react";

import { AccessModel } from "../models/AccessModel";

interface AccessContextModel {
  accessData: AccessModel;
  setAccessData: (accessData: AccessModel) => void;
  changeSituation: (situation: string) => void;
}

interface AccessContextProps {
  children?: ReactNode;
}

const AccessContext = createContext({} as AccessContextModel);

export function AccessContextProvider({
  children,
  ...rest
}: AccessContextProps) {
  const [accessData, setAccessData] = useState<AccessModel>({
    email: "",
    password: "",
    confirmPassword: "",
    situation: "to-do",
  });

  function changeSituation(situation: string) {
    setAccessData({
      ...accessData,
      situation: situation,
    });
  }

  return (
    <AccessContext.Provider
      value={{
        accessData,
        setAccessData,
        changeSituation,
      }}
    >
      {children}
    </AccessContext.Provider>
  );
}

export function useAccessContext() {
  const accessContext = useContext(AccessContext);
  const { accessData, setAccessData, changeSituation } = accessContext;

  return { accessData, setAccessData, changeSituation };
}
