import React from "react";

// contexts / providers / services / utils
import { useProductContext } from "../contexts/ProductContext";
import { moneyFormat } from "../utils/moneyFormat";

// styles, stylized components, etc
import styles from "../styles/components/SimplePurchaseSummary.module.scss";
import { Container, Divider, Grid, Header } from "semantic-ui-react";

function SimplePurchaseSummary() {
  const { productData } = useProductContext();

  return (
    <Container className={styles.simplePurchaseSummaryContainer}>
      <Header className={styles.product} textAlign="center">
        <h4>Produto: {productData.nome}</h4>

        <Header.Subheader className={styles.productDescription}>
          <p>
            Plano mensal com 15 leiautes exclusivo para clientes Domínio.
            <br />+ Possibilidade de adquirir novos leiautes
            <strong> após </strong>sua contratação.
          </p>
        </Header.Subheader>
      </Header>
      <Container className={styles.containerSummary}>
        <Grid textAlign="center" className={styles.listProduct}>
          <Grid.Column width={8} className={styles.listDescriptions}>
            <p>Adesão</p>
            <p>Mensalidade</p>
            {productData.desconto !== 0 && (
              <div className={styles.discount}>
                <p className={styles.discount}>Desconto</p>
              </div>
            )}
          </Grid.Column>

          <Grid.Column width={8} className={styles.listValues}>
            <p>{moneyFormat(Number(productData.adesao))}</p>
            <p>{moneyFormat(Number(productData.mensalidade))}</p>
            {productData.desconto !== 0 && (
              <div className={styles.discount}>
                <p> - {moneyFormat(Number(productData.desconto))}</p>
              </div>
            )}
          </Grid.Column>
        </Grid>
        <Divider section className={styles.divider} />
        <Grid textAlign="center" className={styles.listTotal}>
          <Grid.Column width={8} className={styles.totalDescription}>
            <h4>Total</h4>
          </Grid.Column>

          <Grid.Column width={8} className={styles.totalValue}>
            <h4>
              {moneyFormat(
                Number(productData.adesao) +
                  Number(productData.mensalidade) -
                  Number(productData.desconto)
              )}
            </h4>
          </Grid.Column>
        </Grid>
      </Container>
    </Container>
  );
}

export default SimplePurchaseSummary;
